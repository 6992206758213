import React, { FC, Fragment, useState } from "react";
import Slider from "react-slick";
import DropdownIcon from "../../Icons/dropdown";
import MagnifyIcon from "../../Icons/magnify-icon";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "gatsby";
import Button from "../../Button/Button";
import SelbySecretCard from "../SelbySecretCard";
// import selbyTeamimg from '../../../images/Selby-Team.png';
import { StaticImage } from "gatsby-plugin-image";
import { AllCommunitiesData } from "../../CommunityTemplate/CommunitiesData";
import { Combobox, Transition } from "@headlessui/react";
import { blogList } from "../../../data/blogs";

// const Neighbor_data = [
//   {
//     img: "../../../images/fetured_listing_1.jpg",
//     tag: "Downtown San Diego",
//   },
//   {
//     img: "../../../images/fetured_listing_2.jpg",
//     tag: "Del Cerro",
//   },
//   {
//     img: "../../../images/fetured_listing_4.jpg",
//     tag: "Coronado",
//   },
//   {
//     img: "../../../images/fetured_listing_5.jpg",
//     tag: "College West",
//   },
//   {
//     img: "../../../images/fetured_listing_6.jpg",
//     tag: "City Heights",
//   },
//   {
//     img: "../../../images/fetured_listing_7.jpg",
//     tag: "Demosland",
//   },
//   {
//     img: "../../../images/fetured_listing_1.jpg",
//     tag: "Downtown San Diego",
//   },
//   {
//     img: "../../../images/fetured_listing_2.jpg",
//     tag: "Del Cerro",
//   },
//   {
//     img: "../../../images/fetured_listing_4.jpg",
//     tag: "Coronado",
//   },
//   {
//     img: "../../../images/fetured_listing_5.jpg",
//     tag: "College West",
//   },
//   {
//     img: "../../../images/fetured_listing_6.jpg",
//     tag: "City Heights",
//   },
//   {
//     img: "../../../images/fetured_listing_7.jpg",
//     tag: "Demosland",
//   },
// ];
interface FindNeighborhoodProps {
  remove?: string;
}
const FindNeighborhood: FC<FindNeighborhoodProps> = (props) => {
  const [selected, setSelected] = useState();
  const [query, setQuery] = useState("");

  const filteredPeople =
    query === ""
      ? AllCommunitiesData
      : AllCommunitiesData.filter((person) =>
          person.name
            .toLowerCase()
            .replace(/\s+/g, "")
            .includes(query.toLowerCase().replace(/\s+/g, ""))
        );

  var settings = {
    dots: false,
    infinite: true,
    speed: 3000,
    autoplay: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const renderSlides = () =>
    AllCommunitiesData?.map((value) => (
      <div>
        <Link to={`/san-diego-communities/${value.id}`}>
          <div
            className="relative mx-[2px]"
            style={{
              backgroundImage: "linear-gradient(45deg,#000,#000)",
              zIndex: "1",
            }}
          >
            <img className="opacity-60" src={value.img} alt="sliderimg" />
            <p className="absolute left-[32px] bottom-[23px] font-Quicksand font-[700] text-d-4lg text-white">
              {value.name}
            </p>
          </div>
        </Link>
      </div>
    ));

  return (
    <>
      <div className="pt-[60px] text-[10px] bg-white">
        <div className="container">
          <div className="flex justify-center items-center flex-col gap-[24px]">
            <h2 className="text-d-2xl font-700 text-[#1A1A1A] font-IbarraRealNova">
              Find a Great Neighborhood
              <span className="font-Quicksand font-500"> To Call Home</span>
            </h2>
            <p className="text-[16px] font-500 text-[#666666] font-Quicksand">
              San Diego is known for its diverse mix of neighborhoods—each of which has a style and
              personality all its own.
            </p>
            {/*  <div className="cursor-pointer	max-w-[400px] w-full py-[16px] px-[16px] font-[500] opacity-50 2xs_extar:opacity-50 appearance-none border border-[#B2B2B2] rounded-[4px] outline-none font-Quicksand text-d-3lg">
              <Combobox value={selected} onChange={setSelected}>
                <div className="dropdown flex justify-between items-center">
                  <Combobox.Input
                    className="focus:outline-none font-Quicksand 2xs_extar:font-500 2xs_extar:text-[16px] "
                    displayValue={(person) => person?.name}
                    onChange={(event) => setQuery(event.target.value)}
                    placeholder="Select a neighborhood"
                  />
                  <Combobox.Button className="">
                    <DropdownIcon className="2xs_extar:hidden block" />
                    <MagnifyIcon className="hidden 2xs_extar:block" />
                  </Combobox.Button>
                </div>
                <div className="options">
                  <Transition
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                    afterLeave={() => setQuery("")}
                  >
                    <Combobox.Options className="">
                      {filteredPeople.length === 0 && query !== "" ? (
                        <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                          Nothing found.
                        </div>
                      ) : (
                        filteredPeople.map((person) => (
                          <Combobox.Option
                            key={person.id}
                            className={({ active }) =>
                              `relative cursor-default select-none py-2 pr-4 ${
                                active
                                  ? "bg-teal-600 text-white"
                                  : "text-gray-900"
                              }`
                            }
                            value={person}
                          >
                            {({ selected, active }) => (
                              <>
                                <span
                                  className={`block truncate ${
                                    selected ? "font-medium" : "font-normal"
                                  }`}
                                >
                                  {person.name}
                                </span>
                                {selected ? (
                                  <span
                                    className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                      active ? "text-white" : "text-teal-600"
                                    }`}
                                  >
                                    {/* <CheckIcon className="h-5 w-5" aria-hidden="true" /> 
                                  </span>
                                ) : null}
                              </>
                            )}
                          </Combobox.Option>
                        ))
                      )}
                    </Combobox.Options>
                  </Transition>
                </div>
              </Combobox>
            </div> */}
            {/* <select
              className="cursor-pointer	max-w-[400px] w-full py-[16px] px-[16px] font-[500] opacity-50 appearance-none border border-[#B2B2B2] rounded-[4px] outline-none font-Quicksand text-d-3lg"
              name=""
              id="select-dropdown"
              placeholder="Select a neighborhood"
            >
              <option value="Select a neighborhood">
                Select a neighborhood
              </option>
              <option value="gg">gg</option>
              <option value="dd">dd</option>
              <option value="ww">ww</option>
              <option value="ww">ww</option>
              <option value="ww">ww</option>
              <option value="ww">ww</option>
            </select> */}

            {/* <div
              className="cursor-pointer relative bottom-[58px] left-[168px] xs:left-[150px] xs_small:left-[120px] opacity-30"
            >
              <DropdownIcon />
            </div> */}
          </div>
        </div>

        <div className="pt-[32px] pb-[96px] overflow-hidden">
          <Slider {...settings}>{renderSlides()}</Slider>
        </div>

        <div id={props.remove} className="container">
          <div className="flex justify-between items-center pb-[3px] md:flex-col md:items-start">
            <h2 className="text-d-2xl font-700 text-[#1A1A1A] font-IbarraRealNova md:pb-[10px]">
              Selby Secrets to Real Estate
              <span className="font-Quicksand font-[500]"> Success</span>
            </h2>
            <Link to="/blogs">
              <Button
                type="text"
                rightIcon
                color="black"
                customStyle="text-black hover:bg-black hover:text-white"
              >
                <p>Read all</p>
              </Button>
            </Link>
          </div>
        </div>
      </div>

      <div id={props.remove} className="pt-[56px] bg-[#F7F9FA] pb-[120px] lg:pb-[80px]">
        <div className="container relative">
          <div>
            <div className="md:w-full">
              <StaticImage
                src="../../../images/Selby-Team.jpg"
                alt="sellbyTeam"
                className="md:w-full w-[629px]"
                objectFit="contain"
              />
            </div>

            <div className="absolute right-0 top-[37px] md:right-auto md:relative flex justify-center flex-col md:mt-[-80px] px-[20px]">
              {blogList
                // .sort()
                .slice(0, 3)
                .map((blog) => (
                  <SelbySecretCard
                    category={blog.category}
                    date={blog.date}
                    description={blog.title}
                    link={blog.url}
                  />
                ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FindNeighborhood;
